<template>
  <v-main>
    <top-bar> </top-bar>
    <fluent-appear-exam
      ref="appear"
      v-model="record"
      :is-history="isHistory"
      :loading="loading['exam']"
      @submit="finishExam"
      @section-break="startSectionBreak"
      @exit="exit"
    >
    </fluent-appear-exam>
    <exam-bottom-nav
      :record="record"
      @jump="jumpToQuestion"
      @submit="showFinishExamDialog"
      @end-section="showEndSectionWarningDialog"
    ></exam-bottom-nav>
    <v-dialog v-model="showEndSectionWarning" persistent width="600">
      <dialogue-card
        header="Are you sure?"
        message="You are about to end and submit this section."
        @no="showEndSectionWarning = false"
        @yes="startSectionBreak"
      ></dialogue-card>
    </v-dialog>
    <v-dialog v-model="isFinishExamDialogVisible" persistent width="600px">
      <dialogue-card
        header="Are you sure?"
        message="You are about to end and submit this exam."
        @no="isFinishExamDialogVisible = false"
        @yes="finishExam"
      ></dialogue-card>
    </v-dialog>
    <v-dialog v-model="showEndDialog" persistent width="700px">
      <v-card>
        <v-card-title>Your Result</v-card-title>
        <v-card-text>
          <div v-if="result?.is_evaluated">
            <h5>Answered: {{ result.answered }}</h5>
            <h5>Correct: {{ result.correct }}</h5>
            <h5>Wrong: {{ result.wrong }}</h5>
            <h4>Score: {{ result.score }}</h4>
          </div>
          <div v-else>
            Congratulations, your exam has been submitted. You can check your
            result once the teacher has finished grading
          </div>
          <div class="d-flex justify-end">
            <v-btn color="primary" @click="complete">See other exams</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import TopBar from "@ef/app-shell/components/TheTopBar.vue";
import ExamBottomNav from "@ef/app-shell/components/ExamBottomNav.vue";
import FluentAppearExam from "#ef/exam/components/FluentAppearExam.vue";
import { apiCallUtils } from "/global/utils/mixins";
import { computed } from "vue";
import _ from "lodash";
import DialogueCard from "/global/components/DialogueCard.vue";

export default {
  name: "ExamAppearHistoryLayout",
  components: { DialogueCard, TopBar, ExamBottomNav, FluentAppearExam },
  mixins: [apiCallUtils],
  data() {
    return {
      exam: undefined,
      current: undefined,
      currentSection: undefined,
      time: undefined,
      record: {},
      oldRecord: {},
      loading: {},
      result: undefined,
      showEndDialog: false,
      isFinishExamDialogVisible: false,
      showEndSectionWarning: false
    };
  },
  computed: {
    studentExamId() {
      return this.exam?.student_exam?.id;
    },
    isHistory() {
      return this.$attrs.appear === "history";
    }
  },
  watch: {
    record: {
      deep: true,
      handler(value) {
        for (let key in value) {
          if (typeof value[key] === "object") {
            if (this.oldRecord[key] === undefined) {
              this.recordAnswer(key, value[key]);
            } else if (this.oldRecord[key].file !== value[key].file) {
              this.recordAnswer(key, value[key]);
            } else if (this.oldRecord[key].value !== value[key].value) {
              this.recordAnswer(key, value[key]);
            }
          } else {
            if (this.oldRecord[key] !== value[key]) {
              this.recordAnswer(key, value[key]);
            }
          }
        }
        this.oldRecord = _.cloneDeep(value);
      }
    }
  },
  async created() {
    await this.waitingCall(this.getStudentExam, this.loading, "exam");
    if (!this.exam) {
      await this.$router.replace({ name: "exams-student" });
    }
    this.exam?.questions?.sort((first, second) => first.order - second.order);
    let questionItem;
    if (this.$route.path.includes("section")) {
      this.currentSection = this.exam?.sections.at(this.$attrs.sectionId);
      let totalQuestions = this.exam?.questions;
      if (this.currentSection) {
        totalQuestions = totalQuestions?.filter(
          question => question.section_id === this.currentSection.id
        );
      }
      questionItem = totalQuestions.at(0);
    } else {
      questionItem = this.exam?.questions.at(0);
    }
    this.current = {
      id: questionItem?.id,
      essayId: questionItem?.essay_id
    };
  },
  provide() {
    return {
      exam: computed(() => this.exam),
      current: computed(() => this.current),
      navigated: this.navigated,
      currentSection: computed(() => this.currentSection)
    };
  },
  methods: {
    jumpToQuestion(payload) {
      this.current = payload;
    },
    navigated(id) {
      if (id === this.current.id) return;
      let questionItem = this.exam?.questions?.find(item => item.id === id);
      if (questionItem !== undefined) {
        this.current.id = questionItem.id;
        this.current.essay_id = questionItem.essay_id;
      }
    },
    moveNextSection() {
      let totalSections = this.exam?.sections.length;
      let currentSectionIndex = this.exam?.sections.indexOf(
        this.currentSection
      );
      if (currentSectionIndex < totalSections) {
        currentSectionIndex = currentSectionIndex + 1;
        this.currentSection = this.exam?.sections[currentSectionIndex];
      }
    },
    async showEndSectionWarningDialog() {
      this.showEndSectionWarning = true;
    },
    startSectionBreak() {
      this.moveNextSection();
      this.$router.replace({
        name: `section-break`,
        params: {
          testId: this.studentExamId,
          exam: this.exam,
          nextSection: this.currentSection
        }
      });
      // this.$router.push({
      //   name: "section-break"
      // });
    },
    async getStudentExam() {
      if (this.isHistory) {
        this.exam = await this.$store.dispatch(
          "studentExam/getExamHistoryDetails",
          { studentExamId: this.$attrs.testId }
        );
        this.showEndDialog = false;
        this.record = this.exam.answers;
      } else {
        this.exam = await this.$store.dispatch("studentExam/getStudentExam", {
          studentExamId: this.$attrs.testId
        });
      }
    },
    async recordAnswer(questionId, answerId) {
      if (this.isHistory) return;
      let answerPayload = {
        answerId,
        questionId,
        studentExamId: this.exam?.student_exam?.id
      };
      await this.waitingCall(
        () => {
          this.$store.dispatch("studentExam/recordAnswer", answerPayload);
        },
        this.loading,
        "record"
      );
    },
    showFinishExamDialog() {
      this.isFinishExamDialogVisible = true;
    },
    async finishExam() {
      this.isFinishExamDialogVisible = false;
      if (this.$route.path.includes("history")) {
        this.showEndDialog = false;
      } else {
        await this.waitingCall(
          async () => {
            this.result = await this.$store.dispatch("studentExam/finish", {
              studentExamId: this.studentExamId
            });
            this.$refs.appear.stopClock();
            this.showEndDialog = true;
          },
          this.loading,
          "finish"
        );
      }
    },
    async complete() {
      this.showEndDialog = false;
      await this.$router.push({
        name: "exams-student",
        params: {
          course_id: this.$ielts_course_id,
          batch_id: this.$ielts_batch_id
        }
      });
    },
    async exit() {
      if (!this.isHistory) await this.finishExam();
      else await this.complete();
    }
  }
};
</script>
