<template>
  <div>
    <v-tooltip top open-delay="500">
      <template #activator="{on, attrs}">
        <v-btn icon v-bind="attrs" v-on="on" @click="activate">
          <!--          <img :src="homeIcon" alt="leave-button" />-->
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <span>Go back to dashboard</span>
    </v-tooltip>
    <v-dialog v-model="dialogVisible" width="500" persistent>
      <v-card rounded class="pa-4">
        <v-card-title class="justify-center">
          <div class="d-flex flex-column align-center">
            <img :src="leaveIcon" alt="leave-icon" />
            <p class="mt-2">Are you sure?</p>
          </div>
        </v-card-title>
        <v-card-text
          class="text-center xl:text-lg lg:text-lg md:text-lg sm:text-base"
        >
          Going back to the Dashboard will <b>terminate</b> the exam
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            depressed
            color="primary"
            class="white--text"
            @click="dialogVisible = false"
          >
            No
          </v-btn>
          <v-btn
            depressed
            color="decline"
            class="white--text"
            @click="
              dialogVisible = false;
              $emit('confirm');
            "
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  computed: {
    homeIcon() {
      return require("#ef/exam/assets/img_exam_leave.svg");
    },
    leaveIcon() {
      return require("/global/assets/alertDialogIcons/Icon_Leaving.svg");
    }
  },
  methods: {
    activate() {
      if (this.isHistory) {
        this.$emit("confirm");
      } else {
        this.dialogVisible = true;
      }
    }
  }
};
</script>
