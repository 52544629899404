var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("div", [_vm._m(0)])
    : _vm.tabItems !== undefined
    ? _c(
        "div",
        { class: _vm.classes.container },
        [
          _c(
            "v-row",
            { staticClass: "mb-8", attrs: { "no-gutters": "" } },
            [
              _vm.screenWidth >= 600
                ? _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex align-center pb-2 border-0 border-b-1-2 border-solid border-primary",
                      attrs: { cols: !_vm.isHistory ? _vm.cols.title : `12` },
                    },
                    [
                      _vm.exitable
                        ? _c("exam-exit-button", {
                            staticClass: "mr-1",
                            attrs: { "is-history": _vm.isHistory },
                            on: {
                              confirm: function ($event) {
                                return _vm.$emit("exit")
                              },
                            },
                          })
                        : _vm._e(),
                      !_vm.currentSection
                        ? _c(
                            "span",
                            { staticClass: "dark--text ft-bold xl:text-2xl" },
                            [_vm._v(_vm._s(_vm.exam?.student_exam.title))]
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        { staticClass: "dark--text ft-bold xl:text-2xl" },
                        [
                          _vm._v(
                            _vm._s(_vm.sectionTitles[_vm.current?.id] || "")
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isHistory
                ? _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center justify-center",
                      attrs: { cols: _vm.cols.time },
                    },
                    [
                      _c("fluent-time-display", {
                        ref: "clock",
                        on: { "time-over": _vm.emitTimeOver },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.currentSection
            ? _c("div", { staticClass: "mb-4" }, [
                _c("span", [_vm._v("Instruction:  ")]),
                _c("span", [_vm._v(_vm._s(_vm.currentSection.instruction))]),
              ])
            : _vm._e(),
          _vm.splitView
            ? _c(
                "v-tabs-items",
                {
                  staticClass: "transparent",
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                _vm._l(_vm.tabItems, function (tab, index) {
                  return _c(
                    "v-tab-item",
                    { key: index },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          tab.essayContent
                            ? _c(
                                "v-col",
                                {
                                  class: _vm.classes.essay,
                                  attrs: { cols: _vm.cols.essay },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "split-area bg-white",
                                      class: _vm.classes.splitArea,
                                    },
                                    [
                                      _c("essay-viewer", {
                                        attrs: {
                                          "essay-content": tab.essayContent,
                                          outlined: false,
                                          reactive: true,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              class: _vm.classes.questionGroup,
                              attrs: { cols: _vm.cols.questionGroup },
                            },
                            [
                              _vm.isHistory
                                ? _c(
                                    "div",
                                    [
                                      _vm.exam?.student_exam?.is_evaluated
                                        ? _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "pa-xl-8 mb-xl-8 pa-lg-4 mb-lg-4",
                                              attrs: {
                                                outlined: "",
                                                flat: "",
                                                color: "#C8FFD8",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ft-bold font-weight-bold lg:text-xl md:text-xl xl:text-xl sm:text-xl",
                                                },
                                                [
                                                  _vm._v(
                                                    "Your score is " +
                                                      _vm._s(
                                                        _vm.totalExamScore
                                                      ) +
                                                      "/" +
                                                      _vm._s(
                                                        _vm.exam?.student_exam
                                                          ?.total_points
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "v-card",
                                            {
                                              staticClass: "pa-12 mb-8",
                                              attrs: { outlined: "", flat: "" },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "flex-column" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "pb-4 font-weight-bold",
                                                    },
                                                    [_vm._v("Not evaluated")]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      "You'll be notified When the evaluation will be completed"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              tab.isAdvance
                                ? _c(
                                    "div",
                                    {
                                      class: _vm.isHistory
                                        ? "split-area-history"
                                        : "split-area",
                                    },
                                    _vm._l(
                                      tab.questionGroups,
                                      function (content, i) {
                                        return _c(
                                          "div",
                                          {
                                            key: i,
                                            staticClass: "bg-white",
                                            class: [
                                              _vm.classes.splitArea,
                                              { "mt-10": i > 0 },
                                            ],
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ft-bold xl:text-lg",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(content.heading) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "question-group-viewer",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    record: _vm.localRecords,
                                                    "is-practice-exam":
                                                      _vm.exam.student_exam
                                                        .for_practice,
                                                  },
                                                  on: {
                                                    update:
                                                      _vm.receiveAnswerNew,
                                                  },
                                                },
                                                "question-group-viewer",
                                                content,
                                                false
                                              )
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "split-area fluent-question-display",
                                    },
                                    _vm._l(
                                      tab.questionContent,
                                      function (question, i) {
                                        return _c("question-answer-card-p", {
                                          key: i,
                                          staticStyle: { padding: "0" },
                                          attrs: {
                                            id: `question-${question.id}`,
                                            question: question,
                                            readonly: _vm.isHistory,
                                            value: _vm.recordNewToOld(
                                              _vm.record[question.id],
                                              question.type
                                            ),
                                          },
                                          on: {
                                            input: (v) => {
                                              !_vm.isHistory
                                                ? _vm.receiveAnswerOld(
                                                    v,
                                                    question.id,
                                                    question.type
                                                  )
                                                : ""
                                            },
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "div",
                { staticClass: "single-area d-flex flex-column align-center" },
                _vm._l(_vm.tabItems, function (tab, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "ma-5 d-flex flex-column align-center",
                      style: _vm.styles.singleView,
                    },
                    [
                      tab.essayContent
                        ? _c(
                            "div",
                            { staticStyle: { width: "100%" } },
                            [
                              _c("essay-viewer", {
                                staticClass: "elevation-1",
                                attrs: {
                                  "essay-content": tab.essayContent,
                                  outlined: false,
                                  reactive: true,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { style: _vm.styles.singleArea },
                        _vm._l(tab.questionContent, function (question, i) {
                          return _c("question-answer-card-p", {
                            key: i,
                            attrs: {
                              id: `question-${question.id}`,
                              question: question,
                              readonly: _vm.isHistory,
                              value: _vm.recordNewToOld(
                                _vm.record[question.id],
                                question.type
                              ),
                            },
                            on: {
                              input: (v) => {
                                !_vm.isHistory
                                  ? _vm.receiveAnswerOld(
                                      v,
                                      question.id,
                                      question.type
                                    )
                                  : ""
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "loading-wrapper" } }, [
      _c("div", { attrs: { id: "loading-text" } }, [_vm._v("LOADING")]),
      _c("div", { attrs: { id: "loading-content" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }