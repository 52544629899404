<template>
  <v-card height="inherit" width="inherit" class="pa-6" style="border-radius: 6px">
    <v-card-title class="align-center justify-center flex-column" style="background: #FAFBFD">
      <img :src="icon" alt="icon" />
      <div class="delete-dialogue-title orange--text pt-4">
        {{ header }}
      </div>
    </v-card-title>
    <v-row justify="center" align="center" no-gutters class="px-4">
      <p class="ma-0" style="font-size: 20px;">{{ message }}</p>
    </v-row>
    <v-card-actions class="px-12 py-4">
      <v-row no-gutters justify="space-between">
        <v-col class="mr-4">
          <v-btn text class="yes-button white--text" @click="$emit('yes')">Yes </v-btn>
        </v-col>
        <v-col class="ml-2">
          <v-btn text class="no-button" @click="$emit('no')">No </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "DialogueCard",
  props: {
    header: {
      type: String,
      default: "Warning"
    },
    message: {
      type: String,
      default: "Do you want to perform this operation"
    },
    dialogIcon: {
      type: String,
      default: require("../assets/alertDialogIcons/alert.svg")
    }
  },
  computed: {
    icon() {
      return (this.dialogIcon);
    }
  }
};
</script>

<style scoped lang="scss">
.delete-dialogue-title {
  font-size: 24px !important;
  font-weight: 500;
}

.yes-button {
  height: 48px !important;
  width: 100% !important;
  border-radius: 5px;
  font-size: 18px;
  background-color: $orange;
}

.no-button {
  height: 48px !important;
  width: 100% !important;
  border-radius: 5px;
  font-size: 18px;
  border: $primary solid 1px;
  color: $primary;
}
</style>
