var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-6",
      staticStyle: { "border-radius": "6px" },
      attrs: { height: "inherit", width: "inherit" },
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "align-center justify-center flex-column",
          staticStyle: { background: "#FAFBFD" },
        },
        [
          _c("img", { attrs: { src: _vm.icon, alt: "icon" } }),
          _c(
            "div",
            { staticClass: "delete-dialogue-title orange--text pt-4" },
            [_vm._v(" " + _vm._s(_vm.header) + " ")]
          ),
        ]
      ),
      _c(
        "v-row",
        {
          staticClass: "px-4",
          attrs: { justify: "center", align: "center", "no-gutters": "" },
        },
        [
          _c(
            "p",
            { staticClass: "ma-0", staticStyle: { "font-size": "20px" } },
            [_vm._v(_vm._s(_vm.message))]
          ),
        ]
      ),
      _c(
        "v-card-actions",
        { staticClass: "px-12 py-4" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "space-between" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-4" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "yes-button white--text",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("yes")
                        },
                      },
                    },
                    [_vm._v("Yes ")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "ml-2" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "no-button",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("no")
                        },
                      },
                    },
                    [_vm._v("No ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }