var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "", "open-delay": "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { icon: "" }, on: { click: _vm.activate } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { color: "black" } }, [
                        _vm._v("mdi-arrow-left"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Go back to dashboard")])]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.dialogVisible,
            callback: function ($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-4", attrs: { rounded: "" } },
            [
              _c("v-card-title", { staticClass: "justify-center" }, [
                _c("div", { staticClass: "d-flex flex-column align-center" }, [
                  _c("img", {
                    attrs: { src: _vm.leaveIcon, alt: "leave-icon" },
                  }),
                  _c("p", { staticClass: "mt-2" }, [_vm._v("Are you sure?")]),
                ]),
              ]),
              _c(
                "v-card-text",
                {
                  staticClass:
                    "text-center xl:text-lg lg:text-lg md:text-lg sm:text-base",
                },
                [
                  _vm._v(" Going back to the Dashboard will "),
                  _c("b", [_vm._v("terminate")]),
                  _vm._v(" the exam "),
                ]
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { depressed: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v(" No ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { depressed: "", color: "decline" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                          _vm.$emit("confirm")
                        },
                      },
                    },
                    [_vm._v(" Yes ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }