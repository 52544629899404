<template>
  <div v-if="loading">
    <div id="loading-wrapper">
      <div id="loading-text">LOADING</div>
      <div id="loading-content"></div>
    </div>
  </div>
  <div v-else-if="tabItems !== undefined" :class="classes.container">
    <!--    Header-->
    <v-row no-gutters class="mb-8">
      <v-col
        v-if="screenWidth >= 600"
        :cols="!isHistory ? cols.title : `12`"
        class="d-flex align-center pb-2 border-0 border-b-1-2 border-solid border-primary"
      >
        <exam-exit-button
          v-if="exitable"
          :is-history="isHistory"
          class="mr-1"
          @confirm="$emit('exit')"
        ></exam-exit-button>
        <span v-if="!currentSection" class="dark--text ft-bold xl:text-2xl">{{
          exam?.student_exam.title
        }}</span>
        <span class="dark--text ft-bold xl:text-2xl">{{
          sectionTitles[current?.id] || ""
        }}</span>
      </v-col>
      <v-col
        v-if="!isHistory"
        :cols="cols.time"
        class="d-flex align-center justify-center"
      >
        <fluent-time-display
          ref="clock"
          @time-over="emitTimeOver"
        ></fluent-time-display>
      </v-col>
    </v-row>
    <!--    Split View-->
    <div v-if="currentSection" class="mb-4">
      <span>Instruction: &nbsp;</span>
      <span>{{ currentSection.instruction }}</span>
    </div>
    <v-tabs-items v-if="splitView" v-model="tabIndex" class="transparent">
      <v-tab-item v-for="(tab, index) in tabItems" :key="index">
        <v-row no-gutters>
          <v-col
            v-if="tab.essayContent"
            :cols="cols.essay"
            :class="classes.essay"
          >
            <div class="split-area bg-white" :class="classes.splitArea">
              <essay-viewer
                :essay-content="tab.essayContent"
                :outlined="false"
                :reactive="true"
              ></essay-viewer>
            </div>
          </v-col>
          <v-col :cols="cols.questionGroup" :class="classes.questionGroup">
            <div v-if="isHistory">
              <v-card
                v-if="exam?.student_exam?.is_evaluated"
                outlined
                class="pa-xl-8 mb-xl-8 pa-lg-4 mb-lg-4"
                flat
                color="#C8FFD8"
              >
                <span
                  class="ft-bold font-weight-bold lg:text-xl md:text-xl xl:text-xl sm:text-xl"
                  >Your score is {{ totalExamScore }}/{{
                    exam?.student_exam?.total_points
                  }}</span
                >
              </v-card>
              <v-card v-else outlined class="pa-12 mb-8" flat>
                <v-row class="flex-column">
                  <span class="pb-4 font-weight-bold">Not evaluated</span>
                  <span
                    >You'll be notified When the evaluation will be
                    completed</span
                  >
                </v-row>
              </v-card>
            </div>

            <div
              v-if="tab.isAdvance"
              :class="isHistory ? 'split-area-history' : 'split-area'"
            >
              <div
                v-for="(content, i) in tab.questionGroups"
                :key="i"
                class="bg-white"
                :class="[classes.splitArea, { 'mt-10': i > 0 }]"
              >
                <div class="ft-bold xl:text-lg">
                  {{ content.heading }}
                </div>
                <question-group-viewer
                  :record="localRecords"
                  :is-practice-exam="exam.student_exam.for_practice"
                  v-bind="content"
                  @update="receiveAnswerNew"
                ></question-group-viewer>
              </div>
            </div>
            <div v-else class="split-area fluent-question-display">
              <question-answer-card-p
                v-for="(question, i) in tab.questionContent"
                :id="`question-${question.id}`"
                :key="i"
                style="padding: 0"
                :question="question"
                :readonly="isHistory"
                :value="recordNewToOld(record[question.id], question.type)"
                @input="
                  v => {
                    !isHistory
                      ? receiveAnswerOld(v, question.id, question.type)
                      : '';
                  }
                "
              >
              </question-answer-card-p>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <!--    Single View-->
    <div v-else class="single-area d-flex flex-column align-center">
      <div
        v-for="(tab, index) in tabItems"
        :key="index"
        :style="styles.singleView"
        class="ma-5 d-flex flex-column align-center"
      >
        <div v-if="tab.essayContent" style="width: 100%">
          <essay-viewer
            :essay-content="tab.essayContent"
            :outlined="false"
            :reactive="true"
            class="elevation-1"
          ></essay-viewer>
        </div>
        <div :style="styles.singleArea">
          <question-answer-card-p
            v-for="(question, i) in tab.questionContent"
            :id="`question-${question.id}`"
            :key="i"
            :question="question"
            :readonly="isHistory"
            :value="recordNewToOld(record[question.id], question.type)"
            @input="
              v => {
                !isHistory
                  ? receiveAnswerOld(v, question.id, question.type)
                  : '';
              }
            "
          >
          </question-answer-card-p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExamExitButton from "#ef/exam/components/ExamExitButton.vue";
import FluentTimeDisplay from "#ef/exam/components/FluentTimeDisplay.vue";
import EssayViewer from "#ecf/question-bank/components/GroupQuestion/EssayViewer.vue";
import QuestionGroupViewer from "#ecf/question-bank/components/GroupQuestion/QuestionGroupViewer.vue";
import QuestionAnswerCardP from "#ef/exam/components/QuestionAnswerCardP.vue";
import { ResponsivenessUtils } from "/global/utils/mixins.js";
import { scrollTo } from "/global/utils/helpers";
import _ from "lodash";

export default {
  inject: ["exam", "current", "currentSection"],
  components: {
    ExamExitButton,
    FluentTimeDisplay,
    EssayViewer,
    QuestionGroupViewer,
    QuestionAnswerCardP
  },
  mixins: [ResponsivenessUtils],
  model: {
    prop: "record",
    event: "update:record"
  },
  props: {
    record: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: true
    },
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabIndex: 0,
      localRecords: {}
    };
  },
  computed: {
    splitView() {
      return this.exam?.student_exam?.view?.toLowerCase() === "split";
    },
    isLastSection() {
      if (this.currentSection) {
        let lastSectionIndex = this.exam?.sections?.length - 1;
        let currentSectionIndex = this.exam?.sections?.indexOf(
          this.currentSection
        );
        return lastSectionIndex === currentSectionIndex;
      } else return true;
    },

    totalExamScore() {
      let totalGainedScore = 0;
      this.exam?.questions?.forEach(question => {
        if (!isNaN(parseInt(this.exam?.answers[question.id]?.score))) {
          totalGainedScore += parseFloat(
            this.exam?.answers[question.id]?.score
          );
        } else if (question.type === "mcq") {
          if (
            this.exam?.answers[question.id] &&
            this.findAnswerTrueMCQ(
              this.exam?.answers[question.id],
              question.answers
            )
          ) {
            totalGainedScore += parseFloat(question.points);
          }
        } else if (["fib", "match"].includes(question.type)) {
          // question.type === "match" || question.type === "fib"
          let correctAnswers = this.exam?.answers[question.id]?.split(",");
          if (correctAnswers?.includes(question?.answers?.at(0)?.item_text)) {
            totalGainedScore += parseFloat(question.points);
          }
        } else if (question.type === "amcq") {
          if (
            this.exam?.answers[question.id] &&
            this.findAnswerTrueAMCQ(
              this.exam?.answers[question.id],
              question?.answers?.at(0)?.item_text
            )
          ) {
            totalGainedScore += parseFloat(question.points);
          }
        }
      });
      return totalGainedScore;
    },
    tabItems() {
      let splitItemList = [];
      let totalQuestions = this.exam?.questions?.filter(item =>
        this.currentSection
          ? item["section_id"] === this.currentSection.id
          : true
      );
      let allowedEssays = new Set(
        totalQuestions
          .map(item => item.essay_id)
          .filter(item => item !== undefined)
      );
      let essayItem = this.exam?.essays
        ?.filter(item => allowedEssays.has(item.id))
        ?.reduce((acc, curr) => {
          acc[curr.id] = { essay: curr, questions: [], inList: false };
          return acc;
        }, {});
      totalQuestions.forEach(item => {
        if ("essay_id" in item) {
          if (!essayItem[item.essay_id].inList)
            splitItemList.push(essayItem[item.essay_id]);
          essayItem[item.essay_id].questions.push(item);
          essayItem[item.essay_id].inList = true;
        } else {
          splitItemList.push({
            essay: undefined,
            questions: [item]
          });
        }
      });
      return splitItemList?.map(item => this.makeQuestionItem(item)) || [];
    },
    navMap() {
      return this.tabItems.reduce((acc, curr, index) => {
        if (curr.essayContent?.id) {
          acc[curr.essayContent.id] = index;
        } else {
          acc[curr.questionContent[0].id] = index;
        }
        return acc;
      }, {});
    },
    sectionTitles() {
      let questionMap = {};
      let totalQuestions = this.exam?.questions;
      if (this.currentSection) {
        totalQuestions = totalQuestions?.filter(
          question => question.section_id === this.currentSection.id
        );
        totalQuestions.forEach(item => {
          let title = this.currentSection.title;
          if (title) questionMap[item.id] = ` ${title}`;
        });
      }
      return questionMap;
    },
    styles() {
      return {
        singleView: `width: ${this.breakPointValues(320, 800, 800, 1024)}px`,
        singleArea: `width: ${this.breakPointValues(320, 640, 640, 800)}px`
      };
    },
    classes() {
      return {
        container: this.breakPointValues(
          "px-6 py-2",
          "px-8 py-4",
          "px-12 py-6",
          "px-12 py-6"
        ),
        essay: this.breakPointValues("mb-4", "mb-4", "pr-4", "pr-4"),
        questionGroup: this.breakPointValues("", "", "pl-4", "pl-4"),
        splitArea: this.breakPointValues("pa-4", "pa-6", "pa-6", "pa-8")
      };
    },
    cols() {
      return {
        title: this.breakPointValues("", "6", "9", "9"),
        time: this.breakPointValues("12", "6", "3", "3"),
        essay: this.breakPointValues("12", "12", "6", "6"),
        questionGroup: this.breakPointValues("12", "12", "6", "6")
      };
    },
    exitable() {
      return this.isHistory || this.exam?.for_practice;
    }
  },
  watch: {
    current: {
      deep: true,
      handler(value) {
        if (this.splitView) {
          this.tabIndex = this.navMap[value.essayId || value.id];
          if ((this.tabIndex[this.tabIndex]?.isAdvance ?? false) === false)
            scrollTo(`question-${value.id}`, { block: "center" });
        } else {
          scrollTo(`question-${value.id}`, { block: "center" });
        }
      }
    },
    localRecords: {
      deep: true,
      immediate: true,
      handler(value) {
        // this.$emit("update:record", { ...this.record, ...value });
        this.$emit("update:record", value);
      }
    },
    loading: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.localRecords = _.cloneDeep(this.record);
        }
      }
    }
  },
  methods: {
    findAnswerTrueMCQ(userInput, answers) {
      let ansList = JSON.parse(userInput);
      let correctList = answers
        .filter(item => item.is_correct > 0)
        .map(item => item.id);
      let any = false;
      ansList.forEach(item => {
        any = any || correctList.includes(item);
      });
      return any;
    },
    findAnswerTrueAMCQ(userInput, answers) {
      let ansSet = new Set(JSON.parse(userInput));
      let correctSet = new Set(answers?.split(",") ?? []);

      let wa = [];
      let ma = [];
      ansSet.forEach(item => {
        if (!correctSet.has(item)) wa.push(item);
      });
      correctSet.forEach(item => {
        if (!ansSet.has(item)) ma.push(item);
      });
      return wa.length === 0 && ma.length === 0;
    },
    emitTimeOver() {
      if (this.isLastSection) {
        this.$emit("submit");
      } else this.$emit("section-break");
    },
    questionHeading(item, subItem) {
      let questions = item?.questions?.filter(
        q => q.collection_id === subItem.id
      );
      let fistQuestionIndex = questions?.at(0)?.order + 1;
      let lastQuestionIndex = questions?.slice(-1)?.at(0)?.order + 1;

      if (fistQuestionIndex < lastQuestionIndex)
        return `Question ${fistQuestionIndex} - ${lastQuestionIndex}`;
      else return `Question ${fistQuestionIndex}`;
    },
    receiveAnswerNew(payload) {
      for (let question in payload) {
        this.$set(this.localRecords, question, payload[question]);
      }
    },
    receiveAnswerOld(payload, questionId, questionType) {
      let answers = this.recordNewToOld(
        this.localRecords[questionId],
        questionType
      );
      if (questionType === "mcq") {
        if (answers) {
          if (!answers.includes(payload)) {
            answers.push(payload);
          } else {
            answers.splice(answers.indexOf(payload), 1);
          }
        } else {
          answers = [payload];
        }
      } else if (["fib", "desc"].includes(questionType)) {
        answers = [payload];
      }
      this.$set(
        this.localRecords,
        questionId,
        this.recordOldToNew(answers, questionType)
      );
    },
    recordOldToNew(payload, questionType) {
      switch (questionType) {
        case "desc":
          return {
            value: payload[0]?.value,
            file: payload[0]?.filename
          };
        case "mcq":
          if (payload.length === 0) return "";
          else return JSON.stringify(payload);
        case "fib":
          return payload[0];
      }
    },
    recordNewToOld(payload, questionType) {
      if (!payload) return [];
      switch (questionType) {
        case "desc":
          if (this.isHistory)
            return [
              {
                ...payload
              }
            ];
          else
            return [
              {
                value: payload?.value,
                filename: payload?.file
              }
            ];

        case "mcq":
          return JSON.parse(payload);
        case "fib":
          return [payload];
      }
    },
    stopClock() {
      // to be used from outside to stop clock
      this.$refs.clock.endTimer();
    },
    makeQuestionItem(item) {
      if ((item.essay?.question_group?.length ?? 0) > 0)
        return this.makeAdvancedQuestionItem(item);
      else if (item.essay) return this.makeGroupQuestionItem(item);
      else return this.makeSingleQuestionItem(item);
    },
    makeAdvancedQuestionItem(item) {
      let mapCollectionQuestions = item?.questions.reduce((acc, curr) => {
        let c = curr["collection_id"];
        if (c) {
          if (c in acc) {
            acc[c].push(curr);
          } else {
            acc[c] = [curr];
          }
        }
        return acc;
      }, {});

      return {
        isAdvance: true,
        essayContent: item.essay,
        questionGroups: item.essay.question_group
          .filter(g => g.id in mapCollectionQuestions)
          .map(g => ({
            heading: this.questionHeading(item, g),
            content: g.item_text,
            questions: mapCollectionQuestions[g.id],
            answers:
              this.exam?.options[item.essay.id]?.filter(
                a => a.collection_id === g.id
              ) ?? [],
            files: g.files,
            readonly: this.isHistory,
            isHistory: this.isHistory,
            isEvaluation: false
          }))
          .sort((a, b) => a.questions[0].order - b.questions[0].order)
      };
    },
    makeGroupQuestionItem(item) {
      return {
        isAdvance: false,
        essayContent: item.essay,
        questionContent: item.questions
      };
    },
    makeSingleQuestionItem(item) {
      return {
        isAdvance: false,
        questionContent: item.questions
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.split-area {
  overflow: scroll;
  max-height: 580px;
  scrollbar-width: auto;
  scrollbar-color: $primary white;

  @media screen and (max-width: 1600px) {
    max-height: 480px;
  }
}

.split-area-history {
  overflow: scroll;
  max-height: 500px;
  scrollbar-width: auto;
  scrollbar-color: $primary white;

  @media screen and (max-width: 1600px) {
    max-height: 400px;
  }
}

.single-area {
  overflow: scroll;
  max-height: 580px;
  scrollbar-width: auto;
  scrollbar-color: $primary white;
}

.fluent-question-display {
  div {
    margin-bottom: 24px;
  }

  :last-child {
    margin-bottom: 0;
  }
}
</style>
