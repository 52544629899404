var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("top-bar"),
      _c("fluent-appear-exam", {
        ref: "appear",
        attrs: { "is-history": _vm.isHistory, loading: _vm.loading["exam"] },
        on: {
          submit: _vm.finishExam,
          "section-break": _vm.startSectionBreak,
          exit: _vm.exit,
        },
        model: {
          value: _vm.record,
          callback: function ($$v) {
            _vm.record = $$v
          },
          expression: "record",
        },
      }),
      _c("exam-bottom-nav", {
        attrs: { record: _vm.record },
        on: {
          jump: _vm.jumpToQuestion,
          submit: _vm.showFinishExamDialog,
          "end-section": _vm.showEndSectionWarningDialog,
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "600" },
          model: {
            value: _vm.showEndSectionWarning,
            callback: function ($$v) {
              _vm.showEndSectionWarning = $$v
            },
            expression: "showEndSectionWarning",
          },
        },
        [
          _c("dialogue-card", {
            attrs: {
              header: "Are you sure?",
              message: "You are about to end and submit this section.",
            },
            on: {
              no: function ($event) {
                _vm.showEndSectionWarning = false
              },
              yes: _vm.startSectionBreak,
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "600px" },
          model: {
            value: _vm.isFinishExamDialogVisible,
            callback: function ($$v) {
              _vm.isFinishExamDialogVisible = $$v
            },
            expression: "isFinishExamDialogVisible",
          },
        },
        [
          _c("dialogue-card", {
            attrs: {
              header: "Are you sure?",
              message: "You are about to end and submit this exam.",
            },
            on: {
              no: function ($event) {
                _vm.isFinishExamDialogVisible = false
              },
              yes: _vm.finishExam,
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "700px" },
          model: {
            value: _vm.showEndDialog,
            callback: function ($$v) {
              _vm.showEndDialog = $$v
            },
            expression: "showEndDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Your Result")]),
              _c("v-card-text", [
                _vm.result?.is_evaluated
                  ? _c("div", [
                      _c("h5", [
                        _vm._v("Answered: " + _vm._s(_vm.result.answered)),
                      ]),
                      _c("h5", [
                        _vm._v("Correct: " + _vm._s(_vm.result.correct)),
                      ]),
                      _c("h5", [_vm._v("Wrong: " + _vm._s(_vm.result.wrong))]),
                      _c("h4", [_vm._v("Score: " + _vm._s(_vm.result.score))]),
                    ])
                  : _c("div", [
                      _vm._v(
                        " Congratulations, your exam has been submitted. You can check your result once the teacher has finished grading "
                      ),
                    ]),
                _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: { click: _vm.complete },
                      },
                      [_vm._v("See other exams")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }