var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-bottom-navigation",
    {
      staticClass: "elevation-0 border-0 border-t border-solid border-primary",
      attrs: { app: "", grow: "", height: "64" },
    },
    [
      _c(
        "v-row",
        { staticClass: "px-12", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: !_vm.isLastSection ? `8` : `9` },
            },
            [
              _c(
                "v-slide-group",
                {
                  staticClass: "slide-group-component",
                  attrs: { multiple: "", "show-arrows": "" },
                },
                _vm._l(_vm.items, function (item, index) {
                  return _c("v-slide-item", { key: index }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "navigation-button clickable rounded pa-2 mr-2 my-1 d-flex align-center justify-center",
                        class: [_vm.colorClass(item), _vm.highlightClass(item)],
                        on: {
                          click: function ($event) {
                            return _vm.moveTo(item)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.index) + " ")]
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-space-around",
              attrs: { cols: !_vm.isLastSection ? 4 : 3 },
            },
            [
              _c(
                "div",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  staticClass:
                    "control-button clickable pa-2 rounded border border-solid border-primary d-flex align-center justify-center primary--text ft-medium xl:text-lg lg:text-lg md:text-base sm:text-base",
                  class: { "inert invisible": _vm.hideBack },
                  on: { click: _vm.back },
                },
                [_vm._v(" Back ")]
              ),
              _c(
                "div",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  staticClass:
                    "control-button clickable pa-2 rounded border border-solid border-primary d-flex align-center justify-center primary--text ft-medium xl:text-lg lg:text-lg md:text-base sm:text-base",
                  class: { "inert invisible": _vm.hideNext },
                  on: { click: _vm.next },
                },
                [_vm._v(" Next ")]
              ),
              !_vm.isHistory && _vm.isSectionExam && !_vm.isLastSection
                ? _c(
                    "div",
                    {
                      directives: [{ name: "ripple", rawName: "v-ripple" }],
                      staticClass:
                        "control-button clickable e pa-2 rounded bg-primary border border-solid border-primary d-flex align-center justify-center white--text ft-medium xl:text-lg lg:text-lg md:text-base sm:text-base",
                      on: {
                        click: function ($event) {
                          return _vm.$emit(`end-section`)
                        },
                      },
                    },
                    [_vm._v(" End section ")]
                  )
                : _vm._e(),
              !_vm.isHistory
                ? _c(
                    "div",
                    {
                      directives: [{ name: "ripple", rawName: "v-ripple" }],
                      staticClass:
                        "control-button clickable pa-2 rounded bg-orange d-flex align-center justify-center white--text ft-medium xl:text-lg lg:text-lg md:text-base sm:text-base",
                      on: {
                        click: function ($event) {
                          return _vm.$emit(`submit`)
                        },
                      },
                    },
                    [_vm._v(" End exam ")]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }