<template>
  <v-bottom-navigation
    app
    grow
    height="64"
    class="elevation-0 border-0 border-t border-solid border-primary"
  >
    <v-row no-gutters class="px-12">
      <v-col :cols="!isLastSection ? `8` : `9`" class="d-flex align-center">
        <v-slide-group multiple show-arrows class="slide-group-component">
          <v-slide-item v-for="(item, index) in items" :key="index">
            <div
              class="navigation-button clickable rounded pa-2 mr-2 my-1 d-flex align-center justify-center"
              :class="[colorClass(item), highlightClass(item)]"
              @click="moveTo(item)"
            >
              {{ item.index }}
            </div>
          </v-slide-item>
        </v-slide-group>
      </v-col>
      <v-col
        :cols="!isLastSection ? 4 : 3"
        class="d-flex align-center justify-space-around"
      >
        <div
          v-ripple
          class="control-button clickable pa-2 rounded border border-solid border-primary d-flex align-center justify-center primary--text ft-medium xl:text-lg lg:text-lg md:text-base sm:text-base"
          :class="{ 'inert invisible': hideBack }"
          @click="back"
        >
          Back
        </div>
        <div
          v-ripple
          class="control-button clickable pa-2 rounded border border-solid border-primary d-flex align-center justify-center primary--text ft-medium xl:text-lg lg:text-lg md:text-base sm:text-base"
          :class="{ 'inert invisible': hideNext }"
          @click="next"
        >
          Next
        </div>
        <div
          v-if="!isHistory && isSectionExam && !isLastSection"
          v-ripple
          class="control-button clickable e pa-2 rounded bg-primary border border-solid border-primary d-flex align-center justify-center white--text ft-medium xl:text-lg lg:text-lg md:text-base sm:text-base"
          @click="$emit(`end-section`)"
        >
          End section
        </div>
        <div
          v-if="!isHistory"
          v-ripple
          class="control-button clickable pa-2 rounded bg-orange d-flex align-center justify-center white--text ft-medium xl:text-lg lg:text-lg md:text-base sm:text-base"
          @click="$emit(`submit`)"
        >
          End exam
        </div>
        <!--        <div-->
        <!--          v-else-->
        <!--          v-ripple-->
        <!--          class="control-button clickable pa-2 rounded bg-primary border border-solid border-primary d-flex align-center justify-center white&#45;&#45;text ft-medium xl:text-lg lg:text-lg md:text-base sm:text-base"-->
        <!--        >-->
        <!--          Retry-->
        <!--        </div>-->
      </v-col>
    </v-row>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "ExamBottomNav",
  inject: ["exam", "current", "currentSection"],
  props: {
    record: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isSectionExam() {
      return !!this.currentSection;
    },
    isLastSection() {
      return this.currentSection?.order + 1 === this.exam?.sections?.length;
    },
    isHistory() {
      return this.$route.path.includes("history");
    },
    items() {
      let totalQuestions = this.exam?.questions?.filter(item =>
        this.currentSection
          ? item["section_id"] === this.currentSection.id
          : true
      );
      let x = totalQuestions?.map(item => ({
        index: item.order + 1,
        id: item.id,
        essayId: item.essay_id
      }));
      // x?.sort((x, y) => x.index - y.index);
      return x || [];
    },
    maxIndex() {
      return this.items.length - 1;
    },
    navMap() {
      let tabs = {};
      this.items?.forEach(item => {
        let id = item.essayId ?? item.id;
        if (id in tabs) {
          tabs[id].push(item);
        } else {
          tabs[id] = [item];
        }
      });
      let groups = Object.values(tabs);
      if (!groups) return [];
      groups?.sort((a, b) => a[0].index - b[0].index);

      let out = {};
      for (let i in groups) {
        for (let item of groups[i]) {
          let prev = (groups[i - 1] ?? groups[i])[0].index - 1;
          let self = groups[i][0].index - 1;
          let next = (groups[parseInt(i) + 1] ?? groups[i])[0].index - 1;
          out[item?.id] = { prev, self, next };
        }
      }

      return out;
    },
    hideBack() {
      // return this.currentIndex === 0;
      return (
        this.navMap[this.current?.id].prev ===
        this.navMap[this.current?.id].self
      );
    },
    hideNext() {
      return (
        this.navMap[this.current?.id].next ===
        this.navMap[this.current?.id].self
      );
    }
  },
  methods: {
    moveTo(item) {
      this.$emit("jump", { id: item.id, essayId: item.essayId });
    },
    next() {
      let i = this.navMap[this.current?.id]?.next;
      this.$emit("jump", {
        id: this.items[i].id,
        essayId: this.items[i].essayId
      });
    },
    back() {
      let i = this.navMap[this.current?.id]?.prev;
      this.$emit("jump", {
        id: this.items[i].id,
        essayId: this.items[i].essayId
      });
    },
    findAnswerTrueMCQ(userInput, answers) {
      let ansList = JSON.parse(userInput);
      let correctList = answers
        .filter(item => item.is_correct > 0)
        .map(item => item.id);
      let any = false;
      ansList.forEach(item => {
        any = any || correctList.includes(item);
      });
      return any;
    },
    findAnswerTrueAMCQ(userInput, answers) {
      let ansSet = new Set(JSON.parse(userInput));
      let correctSet = new Set(answers?.split(",") ?? []);

      let wrongAnswers = [];
      let missingAnswers = [];
      ansSet.forEach(item => {
        if (!correctSet.has(item)) wrongAnswers.push(item);
      });
      correctSet.forEach(item => {
        if (!ansSet.has(item)) missingAnswers.push(item);
      });
      return Boolean(wrongAnswers.length === 0 && missingAnswers.length === 0);
    },
    colorClass(item) {
      if (this.isHistory) {
        let userInput = this.exam?.answers[item.id];
        if (!userInput) return "normal";
        let questionItem = this.exam?.questions?.find(
          question => question.id === item.id
        );
        if (questionItem.answers[0] === undefined) return "answered";
        if (questionItem.sub_type) {
          let correctAnswers = questionItem.answers[0].item_text.split(",");
          return correctAnswers.includes(userInput) ? "correct" : "wrong";
        } else {
          switch (questionItem.type) {
            case "mcq":
              return this.findAnswerTrueMCQ(userInput, questionItem.answers)
                ? "correct"
                : "wrong";
            case "fib":
              return questionItem.answers[0].item_text === userInput
                ? "correct"
                : "wrong";
            case "desc":
              return "answered";
            case "amcq":
              return this.findAnswerTrueAMCQ(userInput, questionItem.answers[0].item_text)
                ? "correct"
                : "wrong";
            default:
              return "normal";
          }
        }
      } else {
        if (this.record[item.id]) {
          let x = this.record[item.id];
          if (typeof x === "object" && (x.value?.trim().length > 0 || x.file)) {
            return "answered";
          } else if (typeof x === "string" && x.length > 0) return "answered";
        }
        return item.state || "normal";
      }
    },
    highlightClass(item) {
      if (item.id === this.current?.id) return "selected";
      else if (item.essayId === this.current?.essayId && item.essayId)
        return "in-group";
      else return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-group-component::v-deep {
  .v-slide-group__content {
    padding: 0 4px;
  }
}

.navigation-button {
  height: 32px;
  min-width: 32px;
}

.normal {
  background-color: adjust-color(blue, $lightness: 46%);
  color: $primary;

  &.selected {
    outline: 2px solid $primary;
  }

  &.in-group {
    outline: 1px solid $primary;
  }
}

.answered {
  background-color: $primary;
  color: white;

  &.selected {
    outline: 2px solid darkblue;
  }

  &.in-group {
    outline: 1px solid darkblue;
  }
}

.correct {
  background-color: limegreen; //adjust-color(green, $lightness: 17%, $saturation: -10%);
  color: white;

  &.selected {
    outline: 2px solid darkgreen;
  }

  &.in-group {
    outline: 1px solid darkgreen;
  }
}

.wrong {
  background-color: $decline;
  color: white;

  &.selected {
    outline: 2px solid darkred;
  }

  &.in-group {
    outline: 1px solid darkred;
  }
}

.control-button {
  height: 40px;
  width: 140px;
}

.control-button:hover {
  background-color: transparentize($color: $primary, $amount: 0.95);
}
</style>
